<template>
  <section class="container-fluid mt-4 pso-dashboard">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold text-left">
        แผนปฏิบัติราชการด้านพลังงานจังหวัด{{
          currentProvince | province_name_locale
        }}
      </h4>
      <a
        class="btn btn-outline-secondary text-ipepp pointer"
        @click="$router.go(-1)"
      >
        <eva-icon name="arrow-ios-back"></eva-icon>
        ย้อนกลับ
      </a>
    </div>
    <preview-report
      :allowSubmit="allowEdit"
      :fetchFunction="fetchProvinceReports"
      :nationalPlan="currentNationalPlan"
      :province="currentProvince"
      v-if="!disabled"
    ></preview-report>
  </section>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import PreviewReport from '@/components/PreviewReport.vue';
import {find} from 'lodash';

export default {
  name: 'Preview',

  components: {
    PreviewReport,
  },

  data() {
    return {
      disabled: false,
    };
  },

  created() {
    if (!this.currentProvince) {
      this.disabled = true;
    }
  },

  computed: {
    ...mapState(['meta', 'user']),
    ...mapGetters('peno', ['allowEdit']),
    ...mapGetters(['latestNationalPlan']),
    currentProvince() {
      return this.user.province;
    },
    currentNationalPlan() {
      return this.latestNationalPlan;
    }
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReports']),
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped></style>
