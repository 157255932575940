<template>
  <div class="a4">
    <iframe
      v-show="ready"
      frameBorder="0"
      class="iframe-fit border-bottom"
      :src="srcURL"
      @load="loaded"
    >
    </iframe>
    <vue-element-loading
      v-if="!ready"
      :active="true"
      spinner="spinner"
      color="#ED110E"
      class="loading"
      background-color="transparent"
    />
  </div>
</template>

<script>
export default {
  name: 'retry',

  props: {
    source: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      ready: false,
    };
  },

  computed: {
    srcURL() {
      return this.source + '#view=FitH';
    },
  },

  methods: {
    loaded() {
      this.ready = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-fit {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.loading {
  top: 15%;
  bottom: auto;
}
</style>
