<template>
  <section class="mt-4">
    <div class="box box-responsive" v-if="ready">
      <div class="box-header pt-2">
        <div class="d-flex align-items-center">
          <div class="mr-auto py-2">แผนปฏิบัติราชการ</div>
          <button
            v-show="!isImpersonating"
            class="btn btn-action py-1 px-4"
            @click="confirmSubmitReport"
            :disabled="!allowSubmit"
          >
            ส่งแผน
          </button>
          <a
            class="btn btn-success mx-3 py-1 px-4"
            :href="selectedDocument"
            target="_blank"
          >
            Export Docx
          </a>
          <a
            class="link-unstyle mx-3 text-ipepp p-2 link-hover"
            :href="selectedPdf"
            target="_blank"
          >
            EXPORT PDF
            <eva-icon name="file"></eva-icon>
          </a>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-9 pr-0">
            <pdf-viewer :source="selectedPdf"></pdf-viewer>
          </div>
          <div class="col-3 font-weight-bold pl-0">
            <div
              v-for="(report, index) in reports"
              :key="report.id"
              class="p-3 pl-0 report-choices"
              :class="{ active: selectedIndex == index }"
              @click="selectReport(index)"
            >
              {{ reportTitleWithVersion(report) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-element-loading
      v-else
      :active="!ready"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <modal
      name="alert-submit"
      height="auto"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="box">
        <div class="box-body">
          <div class="text-h4 font-weight-bold text-center">
            ยืนยันการส่งแผน
          </div>
          <div class="text-h5 text-center">
            กรุณาตรวจสอบความครบถ้วนของข้อมูลก่อนส่ง
          </div>
          <div class="mt-4 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-light mr-3"
              @click="$model.hide('alert-submit')"
            >
              ยกเลิก
            </button>
            <button type="button" class="btn btn-action" @click="submitReport">
              ส่งแผน
            </button>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import PdfViewer from '@/components/PdfViewer.vue';
import {mapState, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'PreviewReport',

  components: {
    PdfViewer,
  },

  props: {
    nationalPlan: {
      type: Object,
      required: true,
    },
    province: {
      type: Object,
      required: true,
    },
    fetchFunction: {
      type: Function,
      required: true,
    },
    allowSubmit: {
      type: Boolean,
      required: false,
    },
    /*[
      {
        id: 1,
        title: '',
        pdf: '',
      }
    ]*/
  },

  data() {
    return {
      reports: [],
      selectedIndex: 0,
      ready: false,
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters('auth', ['isImpersonating']),
    ...mapGetters(['reportTitle']),
    selectedPdf() {
      return this.reports[this.selectedIndex]
        ? this.reports[this.selectedIndex].pdf_url || '//'
        : '//';
    },
    selectedDocument() {
      return this.reports[this.selectedIndex]
        ? this.reports[this.selectedIndex].document_url || '//'
        : '//';
    },
  },

  methods: {
    init() {
      this.fetchFunction({
        nationalPlan: this.nationalPlan,
        province: this.province,
      })
        .then((data) => {
          this.reports = data;
          this.ready = true;
        })
        .catch(() => {
          this.ready = false;
        });
    },
    selectReport(index) {
      this.selectedIndex = index;
    },
    reportTitleWithVersion(report) {
      let version = 'ไม่ระบุ';
      switch (report.version) {
        case 1:
          version = 'แบบร่าง';
          break;
        case 2:
          version = 'ส่งแผนครั้งที่ 1';
          break;
        case 3:
          version = 'ส่งแผนครั้งที่ 2';
          break;
      }
      const title = this.reportTitle(this.nationalPlan, this.province);

      return title + ' ' + version;
    },
    confirmSubmitReport() {
      this.$modal.show('alert-submit');
    },
    submitReport() {
      window.axios
        .post('/api/current_report/submit')
        .then((response) => {
          this.$router.push({
            name: 'peno.overview.intro',
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.report-choices {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid #d7dce4;
  cursor: pointer;

  &:hover {
    background-color: $brand-color-600;
    color: #fff;
  }

  &.active {
    border-left: 4px solid $red-color;
  }
}

.link-hover:hover {
  background-color: #eee;
  // color: #fff !important;
  border-radius: 3px;
}
</style>
